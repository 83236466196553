<template>
  <template v-if="disableBackdrop">
    <div
      ref="element"
      class="avv_dialog resize overflow-hidden shadow-lg absolute top-0 z-[1001] w-[600px] h-[400px]"
      :class="{ 'cursor-grabbing': isDragging }"
      v-bind="$attrs"
    >
      <div
        class="cursor-custom flex justify-between"
        :class="{ 'cursor-grab': !isDragging, 'cursor-grabbing': isDragging }"
        role="button"
        tabindex="0"
        @mousedown="onMouseDown"
      >
        <h2 v-text="title"></h2>
        <button type="button" @click="close">
          <i class="material-icons">close</i>
        </button>
      </div>
      <slot />
    </div>
  </template>
  <template v-else>
    <div class="avv_dialog-wrapper" :class="wrapperClass">
      <div ref="element" class="avv_dialog" v-bind="$attrs">
        <slot />
      </div>
    </div>
  </template>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useDraggable } from '../editor/dialogs/dialogs/others/draggable'

export default defineComponent({
  inheritAttrs: false,
  props: ['wrapperClass', 'disableBackdrop', 'title'],
  emits: ['close'],
  setup: (props, ctx) => {
    const element = ref<HTMLElement>()
    const allowDrag = ref(true)
    const { onMouseDown, moveToCenter, isDragging } = useDraggable(
      element,
      allowDrag
    )

    watch(
      element,
      (newValue) => {
        if (newValue) {
          moveToCenter()
        }
      },
      { flush: 'post' }
    )
    const close = () => ctx.emit('close', null)

    return {
      wrapperClass: props.wrapperClass,
      disableBackdrop: props.disableBackdrop,
      title: props.title,
      element,
      onMouseDown,
      close,
      isDragging
    }
  }
})
</script>
